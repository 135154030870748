import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {InputAdornment} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import {useFormik} from 'formik';
import React, {useState} from 'react';
import MeetingServices from 'services/meetingServices';
import Popper from '@mui/material/Popper';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Base64} from 'js-base64';

yup.addMethod(yup.string, 'mName', function (errorMessage) {
    return this.test('test-mName', errorMessage, function (value) {
        const {path, createError} = this;

        if (value !== undefined) {
            if (value.indexOf('/*/*') > -1) return false;
        }

        return true || createError({path, message: errorMessage});
    });
});

const MeetingForm = ({getMeetings}) => {
    const {t, i18n} = useTranslation();
    const [meetingId, setMeetingId] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const userName = useSelector((state) => state.main.user.name);
    const userSurname = useSelector((state) => state.main.user.surname);
    const userEmail = useSelector((state) => state.main.user.email);
    const userId = useSelector((state) => state.main.user.id);

    const validationSchema = yup.object({
        meetingName: yup
            .string(t('requiredMeetingTitle'))
            .min(2, t('validMin5'))
            .trim()
            .required(t('requiredMeetingTitle'))
            .mName(t('validMeetingTitle'))
    });

    const handleClick = (event) => {
        let hashedMeetinDetails = Base64.encode(`?meetingId=${meetingId}&userId=&userName=`);
        navigator.clipboard.writeText(`${process.env.REACT_APP_URL}${i18n.language}/meeting/go/${hashedMeetinDetails}`);
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setTimeout(() => {
            setAnchorEl(null);
        }, 1000);
    };

    const handleJoinMeeting = () => {
        setIsLoading(true); // Butona tıklanınca yüklenme durumunu başlat
        window.location.href = `${process.env.REACT_APP_PROJECT_COMPANY}${i18n.language}/meeting/${Base64.encode(
            `?meetingId=${meetingId}&userId=${userId}&userName=${userName} ${userSurname}&email=${userEmail}`
        )}`;
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const initialValues = {
        meetingName: ''
    };
    const onSubmit = (values) => {
        let base64MeetingName = Buffer.from(values.meetingName).toString('base64');
        createQuicklyMeeting(base64MeetingName);
    };

    function createQuicklyMeeting(meetingName) {
        var promise = MeetingServices.createQuicklyMeeting(meetingName);
        promise
            .then((response) => {
                if (response) {
                    setMeetingId(response.data);
                    getMeetings();
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit
    });

    return (
        <Box sx={{marginBottom: '40px'}}>
            <form onSubmit={formik.handleSubmit} style={{ width: '100%', marginBottom: '40px' }}>
                <Grid container width={'100%'} paddingTop={5}>
                    <Grid item xs={12} paddingBottom={2}>
                        <Box sx={{display: 'flex'}}>
                            <TextField
                                placeholder={t('meetingTitleRequired')}
                                variant="outlined"
                                name={'meetingName'}
                                type={'text'}
                                className={'border-gray'}
                                fullWidth
                                value={formik.values.meetingName}
                                onChange={formik.handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            onClick={() => {
                                                setMeetingId('');
                                                formik.values.meetingName = '';
                                            }}
                                            sx={{cursor: 'pointer'}}
                                        >
                                            <CancelOutlinedIcon
                                                sx={
                                                    meetingId == ''
                                                        ? {display: 'none'}
                                                        : {display: 'block'}
                                                }
                                            />
                                        </InputAdornment>
                                    )
                                }}
                                sx={{backgroundColor: '#fff'}}
                                error={
                                    formik.touched.meetingName &&
                                    Boolean(formik.errors.meetingName)
                                }
                                helperText={
                                    formik.touched.meetingName && formik.errors.meetingName
                                }
                                FormHelperTextProps={{
                                    style: {
                                        padding: 0,
                                        margin: 0
                                    }
                                }}
                            />
                        </Box>
                    </Grid>





                    <Grid item container xs={12}>
                        <Box display="flex" justifyContent={'end'} width={'100%'}>
                            <Button
                                disabled={meetingId ? true : false}
                                type={'submit'}
                                variant={'contained'}
                                sx={{
                                    width: '100%',
                                    padding: '15px',
                                    background: 'linear-gradient(to right, #d32f2f, #f44336, #ff5252) !important'
                                }}
                            >
                                {t('createMeetingLink')}
                            </Button>
                        </Box>
                        {meetingId && (
                            <>
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    width={'100%'}
                                    marginTop={5}
                                >
                                    <TextField
                                        className={'border-gray'}
                                        value={`/meeting/go/${Base64.encode(`?meetingId=${meetingId}&userId=&userName=`)}`}
                                        fullWidth
                                        inputProps={{style: {color: '#f44336'}}}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <ContentCopyIcon
                                                        sx={{cursor: 'pointer'}}
                                                        onClick={handleClick}
                                                    />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <Popper
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        style={{zIndex: 9999}}
                                    >
                                        <Box
                                            sx={{
                                                border: '0.5px solid gray',
                                                p: 1,
                                                bgcolor: 'background.paper',
                                                borderRadius: 2
                                            }}
                                        >
                                            {t('copied')}
                                        </Box>
                                    </Popper>
                                </Box>
                                <Box sx={{marginTop: '30px', width: '100%'}}>
                                    <Button
                                        onClick={handleJoinMeeting}
                                        size={'large'}
                                        variant={'contained'}
                                        sx={{
                                            width: '100%',
                                            padding: '15px',
                                            background: 'linear-gradient(to right, #d32f2f, #f44336, #ff5252) !important',
                                            color: '#fff'
                                        }}
                                        disabled={meetingId == '' ? true : false}
                                    >
                                        {isLoading ? <CircularProgress size={24} color="inherit" /> : t('joinMeeting')}
                                    </Button>
                                </Box>
                            </>
                        )}
                    </Grid>

                    {/*bu gridi ve içine bir field koyunca görüntü düzeliyor */}
                    <Grid
                        item xs={12} md={6} paddingBottom={2} display={'flex'}
                        sx={{ visibility: 'hidden' }}
                    >
                        <TextField fullWidth />
                    </Grid>
                    {/*bu gridi ve içine bir field koyunca görüntü düzeliyor */}


                </Grid>
            </form>
        </Box>
    );
};

MeetingForm.propTypes = {
    getMeetings: PropTypes.func
};

export default MeetingForm;
