/* eslint-disable react/no-unescaped-entities */
import {Checkbox, FormControlLabel, InputAdornment, Tooltip} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import loginTypeEnum from 'enums/loginTypeEnum';
import {useFormik} from 'formik';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {AccountServices} from 'services/index';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import Modal from '@mui/material/Modal';
import {useTranslation} from 'react-i18next';
import {Contracts} from '../../../../../enums/contractsEnum';
import {LoadingButton} from '@mui/lab';
import {Email as EmailIcon, Lock as LockIcon} from '@mui/icons-material';

yup.addMethod(yup.string, 'emailSyntaxCheck', function (errorMessage) {
    return this.test('test-card-length', errorMessage, function (value) {
        const {path, createError} = this;

        const re = /^[a-zA-Z0-9.'_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return re.test(String(value).toLowerCase()) ? true : createError({path, message: errorMessage});
    });
});

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '1400px',
    bgcolor: 'background.paper',
    p: 4,
    height: '90vh',
    overflow: 'scroll',
    borderRadius: '5px'
};

const Form = () => {
    const {t, i18n} = useTranslation();
    let history = useHistory();
    // eslint-disable-next-line no-unused-vars
    const [buttonDisabled, setButtonDisabled] = React.useState(false);
    const [knowledgeModalShow, setKnowledgeModalShow] = React.useState(false);
    const [knowledgeModalType, setKnowledgeModalType] = React.useState('');

    const validationSchema = yup.object({
        name: yup
            .string(t('requiredName'))
            .trim()
            .matches(/^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u, t('validName'))
            .min(2, t('validName'))
            .max(50, t('validName'))
            .required(t('requiredName')),
        surname: yup
            .string(t('validSurname'))
            .trim()
            .matches(/^'?(?:\p{L}\p{M}*)+(?:['\s](?:\p{L}\p{M}*)+)*'?$/u, t('validSurname')).max(40)
            .min(2, t('validSurname'))
            .max(50, t('validSurname'))
            .required(t('requiredSurname')),
        email: yup
            .string()
            .trim()
            .email(t('validEmail'))
            .matches(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, t('validEmail'))
            .emailSyntaxCheck(t('validEmail'))
            .required(t('requiredField')),
        password: yup
            .string()
            .trim()
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/, t('validPasswordRegex'))
            .required(t('validPassword'))
            .min(8, t('validPasswordMin')),
        passwordConfirmation: yup
            .string()
            .trim()
            .required(t('validPassword'))
            .oneOf([yup.ref('password'), null], t('registerCheckPassword')),
        terms: yup.boolean().oneOf([true], t('validTerms')),
        kvkk: yup.boolean().oneOf([true], t('validTerms'))
    });

    const initialValues = {
        recaptcha: '',
        name: '',
        surname: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        terms: false,
        kvkk: false
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit
    });

    function onSubmit(values) {
        let sentValues = {...values};
        sentValues.email = values.email ? values.email.trim() : '';
        sentValues.name = values.name ? values.name.trim() : '';
        sentValues.surname = values.surname ? values.surname.trim() : '';
        sentValues.loginType = loginTypeEnum.StandAlone;
        signUp(sentValues, history);
    }

    function signUp(data, history) {
        setButtonDisabled(true);
        var promise = AccountServices.saveUser(data);
        promise
            .then((response) => {
                setButtonDisabled(false);
                if (response) {
                    let timerInterval;
                    Swal.fire({
                        icon: 'success',
                        title: t('registerSuccessful'),
                        timer: 4000,
                        timerProgressBar: true,
                        showCloseButton: false,
                        showConfirmButton: false,
                        showDenyButton: false,
                        text: t('registerSuccessfulText'),
                        willClose: () => {
                            clearInterval(timerInterval);
                        }
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.timer) {
                            history.push('/login');
                        }
                    });
                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    function Navigator() {

        function goToSection(e, sectionName) {
            let elem = document.getElementById(sectionName);
            e.preventDefault();
            elem && elem.scrollIntoView({behavior: 'smooth', block: 'start'});
        }

        return (
            <Box
                paddingTop={{md: '20px', xs: '20px'}} paddingBottom={{md: '20px', xs: '20px'}}
                sx={{'& a': {color: '#9A78FB', textDecoration: 'none'}}}>
                <Link
                    fontSize={{md: '20px', xs: '16px'}}
                    style={{cursor: 'pointer'}}
                    onClick={e => goToSection(e, 'onbKonu')}
                >
                    1) KONU
                </Link>
                <br/>
                <Link
                    fontSize={{md: '20px', xs: '16px'}}
                    style={{cursor: 'pointer'}}
                    onClick={e => goToSection(e, 'onbTaraflar')}
                >
                    2) TARAFLAR
                </Link>
                <br/>
                <Link
                    fontSize={{md: '20px', xs: '16px'}}
                    style={{cursor: 'pointer'}}
                    onClick={e => goToSection(e, 'onbSozlesmeKonusuHizmet')}
                >
                    3) SÖZLEŞME KONUSU HİZMET
                </Link>
                <br/>
                <Link
                    fontSize={{md: '20px', xs: '16px'}}
                    style={{cursor: 'pointer'}}
                    onClick={e => goToSection(e, 'onbOdemeYontemleri')}
                >
                    4) ÖDEME YÖNTEMLERİ
                </Link>
                <br/>
                <Link
                    fontSize={{md: '20px', xs: '16px'}}
                    style={{cursor: 'pointer'}}
                    onClick={e => goToSection(e, 'onbCaymaHakki')}
                >
                    5) CAYMA HAKKI
                </Link>
                <br/>
                <Link
                    fontSize={{md: '20px', xs: '16px'}}
                    style={{cursor: 'pointer'}}
                    onClick={e => goToSection(e, 'onbHizmetUygulamaKapsamindakiFikriveSinaiHaklar')}
                >
                    6) HİZMET/UYGULAMA KAPSAMINDAKİ FİKRİ VE SINAİ HAKLAR
                </Link>
                <br/>
                <Link
                    fontSize={{md: '20px', xs: '16px'}}
                    style={{cursor: 'pointer'}}
                    onClick={e => goToSection(e, 'onbKisiselVerilerinKorunmasi')}
                >
                    7) KİŞİSEL VERİLERİN KORUNMASI
                </Link>
                <br/>
                <Link
                    fontSize={{md: '20px', xs: '16px'}}
                    style={{cursor: 'pointer'}}
                    onClick={e => goToSection(e, 'onbTelepatiUygulamasininCalismasiHakkinda')}
                >
                    8) TELEPATİ UYGULAMASININ ÇALIŞMASI HAKKINDA
                </Link>
                <br/>
                <Link
                    fontSize={{md: '20px', xs: '16px'}}
                    style={{cursor: 'pointer'}}
                    onClick={e => goToSection(e, 'onbMucbirSebep')}
                >
                    9) MÜCBİR SEBEP
                </Link>
                <br/>
                <Link
                    fontSize={{md: '20px', xs: '16px'}}
                    style={{cursor: 'pointer'}}
                    onClick={e => goToSection(e, 'onbUyusmazlikHalindeBasvuruMercileri')}
                >
                    9) UYUŞMAZLIK HALİNDE BAŞVURU MERCİİLERİ
                </Link>
                <br/>
                <Link
                    fontSize={{md: '20px', xs: '16px'}}
                    style={{cursor: 'pointer'}}
                    onClick={e => goToSection(e, 'onbIstisna')}
                >
                    9) İSTİSNA
                </Link>
                <br/>
            </Box>
        );
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        formik.setFieldValue(name, value.replace(/\s+/g, ''));
    };

    return (
        <Box sx={{padding: {xs: '0 10px 0 10px', md: '0'}}}>
            <Box marginBottom={4}>
                <Typography
                    variant="h5"
                    color="text.tertiary"
                    textAlign={'left'}
                    sx={{
                        fontWeight: 700
                    }}
                >
                    {t('newRegist')}
                </Typography>
                <Box sx={{marginTop: '5px'}}>
          <span style={{color: '#424242', fontSize: '14px', fontWeight: 400}}>

        </span>
                </Box>
            </Box>

            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('registerFirstname')}
                            variant="outlined"
                            name={'name'}
                            className={'border-none'}
                            fullWidth
                            value={formik.values.name}
                            onChange={handleChange}
                            error={
                                formik.touched.name && Boolean(formik.errors.name)
                            }
                            helperText={formik.touched.name && formik.errors.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={t('registerLastname')}
                            variant="outlined"
                            name={'surname'}
                            className={'border-none'}
                            fullWidth
                            value={formik.values.surname}
                            onChange={handleChange}
                            error={formik.touched.surname && Boolean(formik.errors.surname)}
                            helperText={formik.touched.surname && formik.errors.surname}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t('registerEmail')}
                            variant="outlined"
                            name={'email'}
                            type={'email'}
                            className={'border-none'}
                            fullWidth
                            value={formik.values.email}
                            onChange={handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}

                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon/>
                                    </InputAdornment>
                                ),
                                style: {backgroundColor: '#fff', borderRadius: '10px'}
                            }}
                            inputProps={{style: {textTransform: 'lowercase'}}}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Tooltip title={<div style={{padding: 5}}>
                            {t('registerValidationMessage1')}
                            <br/>
                            {t('registerValidationMessage2')}
                            <br/>
                            {t('registerValidationMessage3')}
                            <br/>
                            {t('registerValidationMessage4')}
                        </div>} placement={'bottom-start'}>
                            <TextField
                                label={t('registerPassword')}
                                variant="outlined"
                                name={'password'}
                                type={'password'}
                                className={'border-none'}
                                fullWidth
                                value={formik.values.password}
                                onChange={handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon/>
                                        </InputAdornment>
                                    ),
                                    style: {backgroundColor: '#fff', borderRadius: '10px'}
                                }}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={t('registerRepassword')}
                            variant="outlined"
                            name={'passwordConfirmation'}
                            type={'password'}
                            className={'border-none'}
                            fullWidth
                            value={formik.values.passwordConfirmation}
                            onChange={handleChange}
                            error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
                            helperText={formik.touched.passwordConfirmation && formik.errors.passwordConfirmation}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockIcon/>
                                    </InputAdornment>
                                ),
                                style: {backgroundColor: '#fff', borderRadius: '10px'}
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        sx={{ml: {xs: 2, md: 0}, mr: {xs: 2, md: 0}}}
                        justifyContent={{xs: 'start', md: 'center'}}
                    >
                        <Box width={{xs: '100%', md: '100%'}}>
                            <FormControlLabel
                                control={
                                    <Checkbox sx={{padding: '0px 10px'}}
                                              checked={formik.values.terms}
                                              onChange={formik.handleChange}
                                              error={formik.touched.terms && Boolean(formik.errors.terms)}
                                              name={'terms'}/>}
                                label={
                                    <Typography variant={'subtitle2'} sx={{fontWeight: 500, color: 'text.tertiary'}}>
                                        {
                                            i18n && i18n.language === 'tr' && <>
                                                <Link
                                                    component={'a'}
                                                    color={formik.touched.terms && Boolean(formik.errors.terms) ? '#d32f2f' : '#424242'}
                                                    onClick={() => {
                                                        setKnowledgeModalType('OnBilgilendirme');
                                                        setKnowledgeModalShow(true);
                                                    }}
                                                    underline={'none'}
                                                >
                                                    {t('agreement')} {' '}
                                                </Link>
                                                <span style={{fontWeight: '400'}}>
                        {formik.touched.terms && Boolean(formik.errors.terms) ? t('readandConfirmPending') : t('readandConfirm')}
                        </span>
                                            </>
                                        }
                                        {
                                            i18n && i18n.language === 'en' && <>
                        <span style={{fontWeight: '400'}}>
                          {formik.touched.terms && Boolean(formik.errors.terms) ? t('readandConfirmPending') : t('readandConfirm')}
                        </span>
                                                <Link
                                                    component={'a'}
                                                    color={formik.touched.terms && Boolean(formik.errors.terms) ? '#d32f2f' : '#424242'}
                                                    onClick={() => {
                                                        setKnowledgeModalType('OnBilgilendirme');
                                                        setKnowledgeModalShow(true);
                                                    }}
                                                    underline={'none'}
                                                >
                                                    {t('agreement')} {' '}
                                                </Link>
                                            </>
                                        }
                                    </Typography>
                                }
                                sx={{
                                    '& span': {
                                        fontSize: '14px'
                                    }
                                }}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.kvkk}
                                        onChange={formik.handleChange}
                                        error={formik.touched.kvkk && Boolean(formik.errors.kvkk)}
                                        name={'kvkk'}/>}
                                label={
                                    <Typography variant={'subtitle2'} sx={{fontWeight: 700, color: 'text.tertiary'}}>
                                        {
                                            i18n && i18n.language === 'tr' && <>
                                                <Link
                                                    component={'a'}
                                                    color={formik.touched.kvkk && Boolean(formik.errors.kvkk) ? '#d32f2f' : '#424242'}
                                                    onClick={() => {
                                                        setKnowledgeModalType('kvkk');
                                                        setKnowledgeModalShow(true);
                                                    }}
                                                    underline={'none'}
                                                    sx={{fontWeight: 600}}
                                                >
                                                    {t('kvkkModalTitle')} {' '}
                                                </Link>
                                                <span style={{fontWeight: '400'}}>
                          {formik.touched.kvkk && Boolean(formik.errors.kvkk) ? t('readandConfirmPending') : t('readandConfirm')}
                        </span>
                                            </>
                                        }
                                        {
                                            i18n && i18n.language === 'en' && <>
                                                {formik.touched.kvkk && Boolean(formik.errors.kvkk) ? t('readandConfirmPending') : t('readandConfirm')}
                                                <Link
                                                    component={'a'}
                                                    color={formik.touched.kvkk && Boolean(formik.errors.kvkk) ? '#d32f2f' : 'primary'}
                                                    onClick={() => {
                                                        setKnowledgeModalType('kvkk');
                                                        setKnowledgeModalShow(true);
                                                    }}
                                                    underline={'none'}
                                                    sx={{fontWeight: 600}}
                                                >
                                                    {t('kvkkModalTitle')} {' '}
                                                </Link>
                                            </>
                                        }
                                    </Typography>
                                }
                                sx={{
                                    '& span': {
                                        fontSize: '14px'
                                    }
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        sx={{ml: {xs: 2, md: 0}, mr: {xs: 2, md: 0}}}
                    >
                        <Box
                            display="block"
                            width={'100%'}
                            marginTop={'20px'}
                            marginBottom={{xs: 2, sm: 3}}
                        >
                            <LoadingButton
                                loading={buttonDisabled}
                                color={'primary'}
                                size={'large'}
                                variant={'contained'}
                                type={'submit'}
                                sx={{
                                    fontWeight: 500,
                                    padding: '13px',
                                    width: '100%',
                                    borderRadius: '10px',
                                    backgroundColor: '#f44336 !important'
                                }}
                            >
                                {t('accountSignUp')}
                            </LoadingButton>
                        </Box>


                        <Box
                            display="flex"
                            flexDirection={{xs: 'column', sm: 'row'}}
                            alignItems={{xs: 'stretched', sm: 'center'}}
                            justifyContent={'center'}
                            width={'100%'}
                        >
                            <Box marginBottom={{xs: 1, sm: 3}}>
                                <Typography variant={'subtitle2'} fontWeight="500" color="text.tertiary"
                                            sx={{display: {xs: 'flex', sm: 'block'}, justifyContent: 'center'}}>
                                    <Link
                                        component={'a'}
                                        color={'primary'}
                                        onClick={() => history.push('/login')}
                                        underline={'none'}
                                        sx={{fontWeight: 500, cursor: 'pointer'}}
                                    >
                                        {t('accountSignIn')}
                                    </Link>
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </form>

            <Modal
                open={knowledgeModalShow}
                onClose={() => setKnowledgeModalShow(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {knowledgeModalType == 'kvkk' ? 'KVKK Aydınlatma Metni' : 'Ön Bilgilendirme Koşulları'}
                    </Typography>

                    <Navigator/>

                    <Typography id="modal-modal-description" sx={{mt: 2}}>
                        <div
                            dangerouslySetInnerHTML={{__html: knowledgeModalType == 'kvkk' ? Contracts.KvkkText : Contracts.KnowLedgeText}}
                        />
                    </Typography>
                </Box>
            </Modal>
        </Box>
    );
};

export default Form;
