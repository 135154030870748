import {combineReducers} from 'redux';
import {
  CHANGE_AUTHENTICATION,
  LOG_OUT,
  SET_APPLICATION_INFORMATION_IS_LOADED,
  SET_CALENDAR_DATE,
  SET_CONTAINER_LOADER, SET_COUNTRY_CODE,
  SET_CURRENT_PRODUCT, SET_IS_CURRENCY_DOLLAR,
  SET_IS_LOGIN,
  SET_IS_MOBILE,
  SET_JWT,
  SET_PACKAGES,
  SET_PAGE_SIZE,
  SET_PRIVILEGE_NAMES,
  SET_PRIVILEGES,
  SET_SELECTED_PACKAGE,
  SET_USER,
  SET_HUB_CONNECTION,
  SET_INCOMING_CALL,
  SET_OUTGOING_CALL,
  SET_ONLINE_USERS,
  SET_OUT_GOING_USER_NAME
} from './types';

const initialState = {
  isAuthenticated: false,
  jwt: null,
  user: {},
  privileges: [],
  privilegeNames: {},
  applicationInformationIsLoaded: false,
  containerLoader: false,
  currentProduct: {},
  isLogin: false,
  onlineUsers: [],
  calendarDate: {'test': 'deneme'},
  isMobile: false,
  isCurrencyDollar: false,
  countryCode: 'TR',
  pageSize: {
    dynamicWidth: 0,
    dynamicHeight: 0
  },
  packages: [{
    id: 'standart',
    title: 'STANDART',
    monthlyAmount: 'reachTheSalesUnit1',
    annualAmount: 'reachTheSalesUnit1',
    suffix: ' ₺ Kullanıcı / Yıl',
    benefit1: '1-10 Kullanıcı',
    benefit2: '*30 Gün Ücretsiz Deneme Sürümü',
    buyNow: true,
    buttonText: 'packetBuyNow',
    userCount: 1,
    licenseType: 'monthly',
    details: [
      {
        id: 'userCount',
        value: 1
      },
      {
        id: 'maxUserCount',
        value: 100
      },
      {
        id: 'maxMeetingTime',
        value: '10s'
      },
      {
        id: 'isCreateQuicklyMeeting',
      },
      {
        id: 'isPlanMeeting',
      },
      {
        id: 'meetingRecording',
      },
      {
        id: 'maxStudyRoom',
        value: 16
      },
      {
        id: 'meetingNoteCreation',
      },
      {
        id: 'customizedBackgrounds',
      },
      {
        id: 'screenShare',
      },
      {
        id: 'inChatMeeting',
      },
    ]
  }, {
    id: 'business',
    title: 'BUSINESS',
    monthlyAmount: 'reachTheSalesUnit1',
    annualAmount: 'reachTheSalesUnit1',
    suffix: ' ₺ Kullanıcı / Yıl',
    benefit1: '11-100 Kullanıcı',
    benefit2: '',
    buyNow: false,
    buttonText: 'packetBuyNow',
    userCount: 11,
    licenseType: 'monthly',
    details: [
      {
        id: 'userCount',
        value: 11
      },
      {
        id: 'maxUserCount',
        value: 300
      },
      {
        id: 'maxMeetingTime',
        value: 'unlimited'
      },
      {
        id: 'isCreateQuicklyMeeting',
      },
      {
        id: 'isPlanMeeting',
      },
      {
        id: 'meetingRecording',
      },
      {
        id: 'maxStudyRoom',
        value: 16
      },
      {
        id: 'meetingNoteCreation',
      },
      {
        id: 'customizedBackgrounds',
      },
      {
        id: 'screenShare',
      },
      {
        id: 'inChatMeeting',
      },
    ]
  }, {
    id: 'enterprise',
    title: 'ENTERPRISE',
    monthlyAmount: 'reachTheSalesUnit1',
    annualAmount: 'reachTheSalesUnit1',
    suffix: '',
    benefit1: '100+ Kullanıcı',
    benefit2: '',
    buyNow: false,
    buttonText: 'reachTheSalesUnit',
    userCount: 101,
    licenseType: 'monthly',
    details: [
      {
        id: 'userCount',
        value: '+100'
      },
      {
        id: 'maxUserCount',
        value: 500
      },
      {
        id: 'maxMeetingTime',
        value: '10s'
      },
      {
        id: 'isCreateQuicklyMeeting',
      },
      {
        id: 'isPlanMeeting',
      },
      {
        id: 'meetingRecording',
      },
      {
        id: 'maxStudyRoom',
        value: 16
      },
      {
        id: 'meetingNoteCreation',
      },
      {
        id: 'customizedBackgrounds',
      },
      {
        id: 'screenShare',
      },
      {
        id: 'inChatMeeting',
      },
    ]
  }],
  hubConnection: null,
  incomingCall: null,
  outGoingCall: null,
  outGoingCallUserName: ''
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_AUTHENTICATION:
      return {
        ...state,
        isAuthenticated: action.payload
      };
    case SET_JWT:
      return {
        ...state,
        jwt: action.payload
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload
      };
    case SET_PRIVILEGES:
      return {
        ...state,
        privileges: action.payload
      };
    case SET_APPLICATION_INFORMATION_IS_LOADED:
      return {
        ...state,
        applicationInformationIsLoaded: action.payload
      };
    case SET_PRIVILEGE_NAMES:
      return {
        ...state
      };
    case SET_CONTAINER_LOADER:
      return {
        ...state,
        containerLoader: action.payload
      };
    case SET_CURRENT_PRODUCT:
      return {
        ...state,
        currentProduct: action.payload
      };
    case SET_SELECTED_PACKAGE:
      return {
        ...state,
        selectedPackage: action.payload
      };
    case SET_PACKAGES:
      return {
        ...state,
        packages: action.payload
      };
    case SET_IS_LOGIN:
      return {
        ...state,
        AAAA: action.payload
      };
    case SET_CALENDAR_DATE:
      return {
        ...state,
        calendarDate: action.payload
      };
    case LOG_OUT:
      return initialState;
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload
      };
    case SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload
      };
    case SET_IS_CURRENCY_DOLLAR:
      return {
        ...state,
        isCurrencyDollar: action.payload
      };
    case SET_COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.payload
      };
    case SET_INCOMING_CALL:
      return {
        ...state,
        incomingCall: action.payload
      };
    case SET_OUTGOING_CALL:
      return {
        ...state,
        outGoingCall: action.payload
      };
    case SET_ONLINE_USERS:
      return {
        ...state,
        onlineUsers: action.payload
      };
    case SET_HUB_CONNECTION:
      return {
        ...state,
        hubConnection: action.payload
      };
    case SET_OUT_GOING_USER_NAME:
      return {
        ...state,
        outGoingCallUserName: action.payload
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  main: mainReducer,
});

export default rootReducer;
